import type { Theme } from '@mui/material';
import React from 'react';

const autocomplete = (): Theme['components'] => ({
  MuiAutocomplete: {
    defaultProps: {
      ChipProps: {
        size: 'small',
      },
      popupIcon: <i className='material-symbols-arrow-drop-down-rounded' />,
      clearIcon: (
        <i className='material-symbols-close-rounded w-[20px] h-[20px]' />
      ),
    },
    styleOverrides: {
      root: {
        '& .MuiButtonBase-root.Mui-disabled i, & .MuiButtonBase-root.Mui-disabled svg':
          {
            color: 'var(--mui-palette-action-disabled)',
          },
        '& .MuiOutlinedInput-input': {
          height: '1.4375em',
        },
      },
      input: {
        '& + .MuiAutocomplete-endAdornment': {
          right: '1rem',
          '& i, & svg': {
            fontSize: '1.5rem',
            color: 'var(--mui-palette-text-primary)',
          },
          '& .MuiAutocomplete-clearIndicator': {
            padding: 2,
          },
        },
        '&.MuiInputBase-inputSizeSmall + .MuiAutocomplete-endAdornment': {
          '& i, & svg': {
            fontSize: '1.375rem',
          },
        },
      },
      paper: {
        boxShadow: 'var(--mui-customShadows-lg)',
        marginBlockStart: '0.125rem',
      },
      listbox: ({ theme }) => ({
        '& .MuiAutocomplete-option': {
          paddingBlock: theme.spacing(2),
          '&[aria-selected="true"]': {
            backgroundColor: 'var(--mui-palette-primary-lightOpacity)',
            color: 'var(--mui-palette-primary-main)',
            '&.Mui-focused, &.Mui-focusVisible': {
              backgroundColor: 'var(--mui-palette-primary-mainOpacity)',
            },
          },
        },
        '& .MuiAutocomplete-option.Mui-focusVisible': {
          backgroundColor: 'var(--mui-palette-action-hover)',
        },
      }),
    },
  },
});

export default autocomplete;
